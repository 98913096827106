import React, { useState, useEffect } from 'react';

import Layout from '../../../components/layout';
import Content from '../../../components/licensed-in/content-container';

export default () => {
  const [licensedInOpened, setLicensedInOpened] = useState(false);
  const [getAQuoteOpened, setGetAQuoteOpened] = useState(false);

  const props = {
    licensedInOpened,
    setLicensedInOpened,
    getAQuoteOpened,
    setGetAQuoteOpened
  };

  return (
    <Layout {...props} activePage='licensed-in' id='licensed-in-page'>
      <div className='licensed-in-page-container'>
        <Content title='SECURE INSURANCE IN IOWA' activeCard='iowa' image={'/Iowa_BG.jpg'}>
          <p>
            Insurance in Iowa is experienced significant premium increases in 2018 much like the other states in the federal marketplace. For consumers, finding
            affordable health insurance options are incredibly difficult. Many of the carriers and plans offered in 2016 are no longer available. For the plans
            that have remained, the benefits have changed significantly. Consumers need to compare and contrast how they use healthcare compared to the benefits
            offered by the plans available. This is almost an impossible task for consumers to perform on their own.
          </p>
          <p>
            This is an area where Apollo’s agents excel. Our passion is to assist consumers in finding the very best plan providing the coverage they need while
            remaining affordable. Our advisors are able to identify the type of plan you need and the products best suited for individual insurance, group
            insurance, life insurance or Medicare supplemental insurance.{' '}
          </p>
          <p>
            <span>DESIRE AND PASSION</span>
          </p>
          Fortunately for consumers in Iowa, we are not captive to just one carrier. We are appointed with all health insurance carriers including Medica,
          Aetna, Blue Cross & Blue Shield, Assurity, Cigna, TransAmerica, and a few off-exchange carriers. Our desire and passion are to help potential clients
          find the coverage that meets their healthcare needs while staying within budget. Apollo advisors spend a tremendous amount of time researching the
          regulatory changes and the new carrier product offerings to best serve the consumers in the state of Iowa.
          <p>
            We are noted experts in the marketplace and are certified to assist consumers to find the best plan for them. We listen to our client’s needs
            through an extensive needs analysis discussion. Our agents touch on how healthcare is used, which doctors are seen, which prescriptions are used
            equipping our experienced agents with the necessary information to create the best affordable and applicable solution for the client. Our services
            are free to our clients. We would love to get to know you and/or your family today.{' '}
          </p>
          <span>GET A FREE HEALTH INSURANCE QUOTE IN IOWA TODAY!</span>
        </Content>
      </div>
    </Layout>
  );
};
